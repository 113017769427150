<script setup lang="ts">
import type { DealerInfo } from '~/types/CarstoryApiTypes'
import type { VueMultiselect } from '~/types/vue-multiselect'
import { Multiselect } from 'vue-multiselect'

interface Props { id?: string }

const props = withDefaults(defineProps<Props>(), {
  id: 'multiselect-dealer-picker',
})

// refs
const dealer = defineModel<DealerInfo>()
const multiSelectRef = ref<VueMultiselect<DealerInfo> | null>(null)
const searchQuery = ref('')
const options = ref<DealerInfo[]>([])
const loading = ref(false)

// helper functions
const placeholder = computed(() => {
  return 'Search for Dealer'
})

function createLabel(item: DealerInfo): string {
  return `${item.dealer.name}`
}
function activatePicker() {
  multiSelectRef.value?.activate()
}
defineExpose({
  activatePicker,
})

/** fetching data */
watchEffect(async () => {
  const search = searchQuery.value.slice(0, 3)
  if (search.length === 3) {
    try {
      loading.value = true
      const data = await $fetch(`/api/listings/dealers?search=${searchQuery.value.slice(0, 3)}&cb=${Date.now()}`)
        .catch() as DealerInfo[]
      options.value = data || []
    }
    catch {}
    finally {
      setTimeout(() => loading.value = false, 100)
    }
  }
  else {
    options.value = []
  }
})

// event handlers

// doing this to sanitize input
function onSearchChange(search: string) {
  const sanitized = search.replace(/\s{2,}/, ' ')
  if (multiSelectRef.value!.search !== sanitized) {
    multiSelectRef.value!.search = sanitized
  }
  else {
    searchQuery.value = sanitized
  }
}

const shouldHideContentWrapper = computed(() => {
  return searchQuery.value.length < 3 && !loading.value
})
</script>

<template>
  <div class="cs-search-wrapper">
    <Multiselect
      :id="props.id"
      ref="multiSelectRef"
      v-model="dealer"
      :class="{ 'cs-hide-content-wrapper': shouldHideContentWrapper }"
      :options="options"
      :internal-search="true"
      :reset-after="false"
      open-direction="bottom"
      :placeholder="placeholder"
      :custom-label="createLabel"
      :loading="loading"
      :show-no-results="searchQuery.length >= 3"
      :clear-on-select="false"
      :close-on-select="true"
      :free-text-search="false"
      :show-no-options="false"
      :show-labels="false"
      :option-height="64"
      @search-change="onSearchChange"
    >
      <template #noResult>
        <div class="cs-no-results">
          No Results
        </div>
      </template>

      <template #singleLabel="{ option }">
        <strong>{{ option.dealer.name }}</strong>
      </template>

      <template #option="{ option }">
        <div class="cs-option__desc">
          <span class="cs-option__title font-bold">{{ option.dealer.name }}</span>
          <br>
          <span class="cs-option__small px-2 italic shadow-small">{{ option.location.address }}, </span>
          <span class="cs-option__small shadow-small">{{ option.location.zip }}, </span>
          <span class="cs-option__small shadow-small">{{ option.location.city }}, </span>
          <span class="cs-option__small px-2 shadow-small">{{ option.location.state }}</span>
        </div>
      </template>

      <template #noOptions>
        <div class="cs-no-results">
          Search for Dealer
        </div>
      </template>
    </Multiselect>
  </div>
</template>
