<script setup lang="ts">
import type { DealerInfo } from '~/types/CarstoryApiTypes'

const dealer = ref<DealerInfo>()
const dialog = ref()
const picker = ref()

const navigate = function () {
  if (!dealer.value)
    return
  const uri = UrlBuilder.createSearchUrl({
    dealer_id: dealer.value.dealer.id,
  })
  navigateTo(uri, { external: true })
}

watch(() => dialog.value?.isOpen, (to, from) => {
  if (to && !from)
    picker.value.activatePicker()
})
</script>

<template>
  <UiDialog ref="dialog">
    <form action="" @submit.prevent="navigate">
      <h1 class="mb-6 text-balance pr-8 text-2xl font-bold md:text-3xl">
        Dealer Lookup
      </h1>

      <div class="mb-4 grid">
        <div class="grid gap-2">
          <label for="dealer-picker" class="text-sm">Dealer:</label>
          <UiDealerPicker id="dealer-picker" ref="picker" v-model="dealer" />
        </div>
      </div>

      <UiButton :disabled="!dealer" class="w-full">
        Continue
      </UiButton>
    </form>
  </UiDialog>
</template>
